import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery, useTheme } from '@mui/material';
import VacanziLogo from '../../../assets/icons/logos/vacanzi_30*40.png';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import avatarMen from '../../../assets/images/avatarwomen.jpg';


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#EDEDFC',
      },
    },
  });

  const handleNavigation = (path) => {
    navigate(path);
  };

  const token = localStorage.getItem('authToken'); // Retrieve token from local storage

  return (
    <ThemeProvider theme={lightTheme}>
      <AppBar position="static" sx={{
        minHeight: '80px', // Set your desired height here
        padding: '20px'
      }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Avatar
              src={VacanziLogo}
              alt="Vacanzi Logo"
              sx={{ width: 86, height: 86, display: { xs: 'none', md: 'flex' }}}
            />

            <Typography
              variant="h2"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 4,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'GlanceSansBold',
                color: '#423BFA',
                textDecoration: 'none',
              }}
            >
              Vacanzi
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', mr: '20px' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  ml: 4, // Adjust the menu’s position to move it to the right
                }}
              >
                <MenuItem
                  onClick={() => handleNavigation('/findJobs')}
                  sx={{ mb: 1 }} // Adds space below the item
                >
                  <Typography sx={{ color: '#423BFA', fontWeight: 'bold' }} textAlign="center">
                    Find Jobs
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleNavigation('/jobSeekerAppliedJobs')}
                  sx={{ mb: 1 }} // Adds space below the item
                >
                  <Typography sx={{ color: '#423BFA', fontWeight: 'bold' }} textAlign="center">
                    Applied Jobs
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleNavigation('/jobSeekerSavedJobs')}>
                  <Typography sx={{ color: '#423BFA', fontWeight: 'bold' }} textAlign="center">
                    Saved Jobs
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>

            <Avatar
              src={VacanziLogo}
              alt="Vacanzi Logo"
              sx={{ width: 46, height: 46, display: { xs: 'block', md: 'none' } }}
            />

            <Typography
              variant="body2"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'GlanceSansBold',
                fontWeight: 700,
                color: '#423BFA',
                textDecoration: 'none',
              }}
            >
              Vacanzi
            </Typography>

            <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start',gap:5 }}>
              <Button
                onClick={() => handleNavigation('/findJobs')}
                sx={{
                  my: 2,
                  color: '#423BFA',
                  display: 'block',
                  fontFamily: 'GlanceSansRegular',
                  textTransform: 'none',
                  fontWeight: 'bold'
                }}
              >
                Find Jobs
              </Button>

              <Button
                onClick={() => handleNavigation('/jobSeekerAppliedJobs')}
                sx={{
                  my: 2,
                  color: '#423BFA',
                  display: 'block',
                  fontFamily: 'GlanceSansRegular',
                  textTransform: 'none',
                  fontWeight: 'bold'
                }}
              >
                Applied Jobs
              </Button>

              <Button
                onClick={() => handleNavigation('/jobSeekerSavedJobs')}
                sx={{
                  my: 2,
                  color: '#423BFA',
                  display: 'block',
                  fontFamily: 'GlanceSansRegular',
                  textTransform: 'none',
                  fontWeight: 'bold'
                }}
              >
                Saved Jobs
              </Button>
            </Box>


            {token ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="User Avatar" src={avatarMen} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => handleNavigation('/profile')}>
                    <Typography textAlign="center">Profile</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    localStorage.removeItem('authToken');
                    handleNavigation('/');
                  }}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Stack spacing={isSmallScreen ? 0 : 2} direction={isSmallScreen ? 'column' : 'row'}>
                <Button
                  onClick={() => handleNavigation('/employerSignIn')}
                  variant="text"
                  sx={{
                    color: '#423BFA',
                    fontSize: isSmallScreen ? '14px' : '20px',
                    fontWeight: 'bold',          // Make the text bold
                    fontFamily: 'GlanceSansRegular',
                    textTransform: 'none',       // Ensure no uppercase transformation
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>Employer Login</Typography>
                </Button>

                <Button
                  onClick={() => handleNavigation('/signinsignup')}
                  variant="contained"
                  sx={{
                    backgroundColor: '#423BFA', // Set the background color
                    color: '#FFFFFF',            // Set text color to white
                    fontWeight: 'bold',          // Make the text bold
                    fontSize: isSmallScreen ? '10px' : '20px', // Responsive font size
                    fontFamily: 'GlanceSansBold', // Font family
                    '&:hover': {                  // Optional: keep background color on hover
                      backgroundColor: '#423BFA',
                    },
                  }}
                >
                  <Typography sx={{ textTransform: 'none', fontWeight: 'bold' }}>
                    Candidate Login
                  </Typography>
                </Button>


              </Stack>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

export default ResponsiveAppBar;
