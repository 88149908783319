import React from 'react';
import { Box, Container, Grid, Link, Typography, useMediaQuery, useTheme, Avatar } from '@mui/material';
import { Instagram, Facebook, Twitter, YouTube } from '@mui/icons-material';
import VacanziLogo from '../../../assets/icons/logos/vacanzi_30*40.png';

const Footer = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ backgroundColor: '#162340', color: '#fff', py: 4 }}>
            <Container maxWidth="xl">
                <Grid container spacing={4} justifyContent={isSmallScreen ? 'center' : 'flex-start'} alignItems="center">
                    <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', mb: isSmallScreen ? 2 : 2 }}>
                            <Avatar src={VacanziLogo} alt="Vacanzi Logo" sx={{ width: 36, height: 36, mr: isSmallScreen ? 0 : 1, mb: isSmallScreen ? 1 : 0 }} />
                            <Typography
                                variant="h4"
                                noWrap
                                component="a"
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                    display: 'flex',
                                    fontFamily: "GlanceSansBold",
                                    color: "#FFF",
                                    textDecoration: "none",
                                }}
                            >
                                Vacanzi
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Link href="#" color="inherit" aria-label="Instagram">
                                <Instagram sx={{ color :'#d62976'}}/>
                            </Link>
                            <Link href="#" color="inherit" aria-label="Facebook">
                                <Facebook sx={{ color: '#1877F2'}} />
                            </Link>
                            <Link href="#" color="inherit" aria-label="Twitter">
                                <Twitter sx={{color: '#1DA1F2'}} />
                            </Link>
                            <Link href="#" color="inherit" aria-label="YouTube">
                                <YouTube sx={{color: '#FF0000'}} />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h6" sx={{ mb: 2, textAlign: isSmallScreen ? 'center' : 'left' }}>
                            About Us
                        </Typography>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left', textDecoration: 'none' }}>
                            Careers
                        </Link>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left' ,textDecoration: 'none'}}>
                            Employer Home
                        </Link>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left' ,textDecoration: 'none'}}>
                            Sitemap
                        </Link>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left' ,textDecoration: 'none'}}>
                            Credit
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h6" sx={{ mb: 2, textAlign: isSmallScreen ? 'center' : 'left' }}>
                            Help Center
                        </Typography>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left',textDecoration: 'none' }}>
                            Summons/Notices
                        </Link>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left' ,textDecoration: 'none'}}>
                            Grievances
                        </Link>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left',textDecoration: 'none' }}>
                            Report issue
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h6" sx={{ mb: 2, textAlign: isSmallScreen ? 'center' : 'left' }}>
                           For Job Seekers
                        </Typography>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left' ,textDecoration: 'none'}}>
                        FAQ
                        </Link>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left' ,textDecoration: 'none'}}>
                        Terms & Conditions

                        </Link>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1, textAlign: isSmallScreen ? 'center' : 'left' ,textDecoration: 'none'}}>
                        Security Center
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" sx={{ mb: 2, textAlign: isSmallScreen ? 'center' : 'left' }}>
                            Contact Us
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1, textAlign: isSmallScreen ? 'center' : 'left' }}>
                            No.10, Ramamurthy Nagar, Dodda Banaswadi, Bangalore - 560043
                        </Typography>
                        {/* <Typography variant="body2" sx={{ mb: 1, textAlign: isSmallScreen ? 'center' : 'left' }}>
                            +91 89042 71342 / +91 79758 49598
                        </Typography> */}
                        <Typography variant="body2" sx={{ mb: 1, textAlign: isSmallScreen ? 'center' : 'left' }}>
                            sales@vacanzi.com
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
                            info@vacanzi.com
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ borderTop: '1px solid #444', mt: 4, pt: 2 }}>
                    <Typography variant="body2" align="center" sx={{ mb: 2 }}>
                        ©2024 Vacanzi Rights Reserved
                    </Typography>
                    
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
