import React from "react";
import { Box, TextField, Typography, Button, Grid } from "@mui/material";
import baseURL from "../../../../api/baseUrl";

const educationType = [

  { label: "Diploma", value: "diploma" },
  { label: "Graduate", value: "graduate" },
  { label: "Post Graduate", value: "postGraduate" },
];

const englishProficiency = [
  { label: "No English", value: "noEnglish" },
  { label: "Basic English", value: "basic" },
  { label: "Good English", value: "good" },
];

const experiencestat = [
  { label: "Any", value: "any" },
  { label: "Experienced Only", value: "experienced" },
  { label: "Fresher Only", value: "fresher" },
];

const EmployerCaseOne = ({ formData, handleChange, handleOptionChange , handleNext, handleBack}) => {
  // Ensure formData.additionalRequirements, formData.suggestedTitles, and formData.suggestedCategories are always arrays
  // const additionalRequirements = formData.additionalRequirements || [];
  // const suggestedTitles = formData.suggestedTitles || [];
  // const suggestedCategories = formData.suggestedCategories || [];

  const handleButtonChange = (field, value) => {
    handleOptionChange(field, value);
  };

  // const handleAddRequirement = (requirement) => {
  //   const newRequirements = additionalRequirements.includes(requirement)
  //     ? additionalRequirements.filter((r) => r !== requirement)
  //     : [...additionalRequirements, requirement];
  //   handleOptionChange("additionalRequirements", newRequirements);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const postData = {
      jobId: formData.jobId,
      organizationId: formData.organizationId,
      page: 2,
      minEducation: formData.minimumEducation,
      englishLevel: formData.englishLevel,
      experienceType: formData.experienceLevel,
      totalExpRequired: formData.totalExpRequired,
      minExperience: formData.minExperience,
      otherJobTitleApply: "NA",
      otherJobRoleApply: "NA",
      skillPreferences: formData.skills || [],
      degreeSpecialization: formData.degreeSpecialization,
      gender: formData.gender || "any",
      age: formData.age || "Any",
      minAge: formData.minAge,
      maxAge: formData.maxAge,
      regionalLang: formData.regionalLang || "Any",
      jobDescription: formData.jobDescription,
    };


    try {
      const response = await fetch(`${baseURL}/jobpost/update/page`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        handleNext();  // Move to the next step on successful submission
      } else {
        // Handle error
        console.error("Error submitting data:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ "& .MuiTextField-root": { m: 1, width: "100%" }, p: 2 }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <Typography
            component="legend"
            variant="bold"
            sx={{ fontWeight: "bold" }}
          >
            Candidate Requirements
          </Typography>
          <Typography variant="body2" color="textSecondary">
            We'll use these requirement details to make your job visible to the
            right candidates.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="legend"
            variant="bold"
            sx={{ fontWeight: "bold" }}
            gutterBottom
          >
            Minimum Education
          </Typography>
          <Grid container spacing={2} gutterBottom>
            {educationType.map((education) => (
              <Grid item key={education}>
                <Button
                  variant={
                    formData.minimumEducation === education.value
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    handleButtonChange("minimumEducation", education.value)
                  }
                  sx={{
                    borderRadius: 6,
                    backgroundColor:
                      formData.minimumEducation === education.value
                        ? "#423BFA"
                        : "transparent",
                    border: "1px solid #ccc",
                    color:
                      formData.minimumEducation === education.value
                        ? "#fff"
                        : "#000",
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {education.label}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="legend"
            variant="bold"
            sx={{ fontWeight: "bold" }}
            gutterBottom
          >
            English level required
          </Typography>
          <Grid container spacing={2}>
            {englishProficiency.map((english) => (
              <Grid item key={english}>
                <Button
                  variant={
                    formData.englishLevel === english.value
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    handleButtonChange("englishLevel", english.value)
                  }
                  sx={{
                    borderRadius: 6,
                    backgroundColor:
                      formData.englishLevel === english.value
                        ? "#423BFA"
                        : "transparent",
                    border: "1px solid #ccc",
                    color:
                      formData.englishLevel === english.value ? "#fff" : "#000",
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {english.label}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="legend"
            variant="bold"
            sx={{ fontWeight: "bold" }}
            gutterBottom
          >
            Total experience required
          </Typography>
          <Grid container spacing={2}>
            {experiencestat.map((experience) => (
              <Grid item key={experience.value}>
                <Button
                  variant={
                    formData.experienceLevel === experience.value
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    handleButtonChange("experienceLevel", experience.value)
                  }
                  sx={{
                    borderRadius: 6,
                    backgroundColor:
                      formData.experienceLevel === experience.value
                        ? "#423BFA"
                        : "transparent",
                    border: "1px solid #ccc",
                    color:
                      formData.experienceLevel === experience.value
                        ? "#fff"
                        : "#000",
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {experience.label}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="legend"
            variant="bold"
            sx={{ fontWeight: "bold" }}
          >
            Candidates from which other job title can apply?
          </Typography>
          <TextField
            id="job-title"
            placeholder="Enter Job Title"
            variant="outlined"
            fullWidth
            value={formData.jobTitle}
            onChange={handleChange}
            name="jobTitle"
            size="small"
            sx={{ marginBottom: 2, marginTop: 1 }}
          />
         
          {/* <Grid container spacing={1} sx={{ flexWrap: "wrap", gap: 1, mt: 2 }}>
            {[
              "Assistant Account Executive",
              "Assistant Account Manager",
              "Account Manager",
              "Account Admin",
              "Account Clerk",
              "Audit Executive",
              "Accounts Payable",
              "Admin Executive",
            ].map((suggestedTitle) => (
              <Button
                key={suggestedTitle}
                variant={
                  suggestedTitles.includes(suggestedTitle)
                    ? "contained"
                    : "outlined"
                }
                onClick={() =>
                  handleButtonChange("suggestedTitles", suggestedTitle)
                }
                sx={{
                  borderRadius: 6,
                  backgroundColor: suggestedTitles.includes(suggestedTitle)
                    ? "#423BFA"
                    : "transparent",
                  border: "1px solid #ccc",
                  color: suggestedTitles.includes(suggestedTitle)
                    ? "#fff"
                    : "#000",
                  m: 1,
                }}
              >
                <Typography fontWeight="bold" fontSize="12px">
                  {suggestedTitle}
                </Typography>
              </Button>
            ))}
          </Grid> */}
        </Grid>
        {/* <Grid item xs={12}>
          <Typography
            component="legend"
            variant="bold"
            sx={{ fontWeight: "bold" }}
          >
            Candidates from which other job role/category can apply?
          </Typography>
          <TextField
            id="job-category"
            placeholder="Select job roles"
            variant="outlined"
            fullWidth
            value={formData.jobCategory}
            onChange={handleChange}
            name="jobCategory"
            size="small"
            sx={{ marginBottom: 2, marginTop: 1 }}
          />
          <Grid container spacing={1} sx={{ flexWrap: "wrap", gap: 1, mt: 2 }}>
            {[
              "Accounting & Taxation",
              "Payroll & Transactions",
              "Treasury",
              "Audit & Control",
            ].map((suggestedCategory) => (
              <Button
                key={suggestedCategory}
                variant={
                  suggestedCategories.includes(suggestedCategory)
                    ? "contained"
                    : "outlined"
                }
                onClick={() =>
                  handleButtonChange("suggestedCategories", suggestedCategory)
                }
                sx={{
                  borderRadius: 6,
                  backgroundColor: suggestedCategories.includes(
                    suggestedCategory
                  )
                    ? "#423BFA"
                    : "transparent",
                  border: "1px solid #ccc",
                  color: suggestedCategories.includes(suggestedCategory)
                    ? "#fff"
                    : "#000",
                  m: 1,
                }}
              >
                <Typography fontWeight="bold" fontSize="12px">
                  {suggestedCategory}
                </Typography>
              </Button>
            ))}
          </Grid>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Typography
            component="legend"
            variant="bold"
            sx={{ fontWeight: "bold" }}
          >
            Additional Requirements (Optional)
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Add additional requirement so that we can help you find the right
            candidates.
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{ flexWrap: "wrap", gap: 1, mt: 2 }}
          >
            {[
              "Industry",
              "Gender",
              "Age",
              "Regional Languages",
              "Assets",
              "Skills",
            ].map((requirement) => (
              <Button
                key={requirement}
                variant={
                  additionalRequirements.includes(requirement)
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleAddRequirement(requirement)}
                sx={{
                  borderRadius: 6,
                  backgroundColor: additionalRequirements.includes(requirement)
                    ? "#423BFA"
                    : "transparent",
                  border: "1px solid #ccc",
                  color: additionalRequirements.includes(requirement)
                    ? "#fff"
                    : "#000",
                  m: 1,
                  position: "relative",
                  display: "flex",
                  alignItems: "center", // Center the icon vertically
                  justifyContent: "space-between", // Space between text and icon
                }}
              >
                <Typography fontWeight="bold" fontSize="12px">
                  {requirement}
                </Typography>
                <AddIcon
                  sx={{
                    fontSize: "small",
                    marginLeft: "10px",
                  }}
                />
              </Button>
            ))}
          </Stack>
        </Grid> */}
        <Grid item xs={12}>
          <Typography
            component="legend"
            variant="bold"
            sx={{ fontWeight: "bold" }}
          >
            Job Description
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Describe the responsibilities of this job and other specific
            requirements here.
          </Typography>
          <TextField
            required
            id="job-description"
            placeholder="Job description / Additional requirements"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            value={formData.jobDescription}
            onChange={handleChange}
            name="jobDescription"
            size="small"
            sx={{ marginBottom: 2, marginTop: 1 }}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between",mt: 3 }}>
        <Button
          type="submit"
          variant="contained"
          color="inherit"

          onClick={handleBack}
          sx={{
            backgroundColor: "#423BFA",
            color: "#fff",
            borderRadius: 6,
            px: 3,
            py: 1.5,
            "&:hover": {
              backgroundColor: "#372FD1",
            },
          }}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#423BFA",
            color: "#fff",
            borderRadius: 6,
            px: 3,
            "&:hover": {
              backgroundColor: "#372FD1",
            },
          }}
        >
          Save & Next
        </Button>
      </Box>
    </Box>
  );
};

export default EmployerCaseOne;
