import React from 'react';
import { Box, Card, CardContent, Typography, Chip, Button, IconButton, Container, Avatar,Divider } from '@mui/material';
import { styled } from '@mui/system';
import upComingEvents from '../../../assets/images/EventsImage.png';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import EventIcon from '@mui/icons-material/Event';

const SectionContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '32px',
    marginBottom: '40px',
    borderRadius: '30px',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(to top, rgba(0, 123, 255, 0.1), rgba(255, 255, 255, 0.9) 10%, #ffffff)',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '16px',
    },
}));

const EventCard = styled(Card)(({ theme }) => ({
    maxWidth: 380,
    borderRadius: 16,
    boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '100%',
    },
}));

const Header = styled(Box)({
    position: 'relative',
    padding: '16px',
    backgroundColor: '#1976d2',
    color: '#fff',
});

const Tag = styled(Typography)({
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: '#ff4081',
    borderRadius: 8,
    padding: '4px 8px',
    fontSize: '0.75rem',
    color: '#fff',
});

const JobSeekerUpComingEvents = () => {
    return (
        <SectionContainer>
            <Box display="flex" alignItems="center" padding={2} gap={4} flexDirection={{ xs: 'column', md: 'row' }}>
                {/* Left Image */}
                <Box
                    component="img"
                    src={upComingEvents || 'https://via.placeholder.com/300'}
                    alt="Promotional"
                    sx={{
                        width: { xs: '100%', sm: '80%', md: '35%' },
                        objectFit: 'contain',
                        borderRadius: 4,
                        mb: { xs: 2, md: 0 },
                    }}
                />
                
                {/* Main Event Card */}
                <EventCard>
                    <Header>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Entry closes in 6h
                        </Typography>
                        <Tag variant="caption">Hiring challenge</Tag>
                    </Header>

                    <CardContent sx={{ padding: '24px' }}>
                        {/* Avatar and Organization Name */}
                        <Box display="flex" alignItems="center" gap={1} sx={{ marginBottom: 1 }}>
                            <Avatar src="https://via.placeholder.com/40" alt="Organization Logo" sx={{ width: 44, height: 44 }} />
                            <Typography variant="body2"  fontWeight='bold'>
                            Vacanzi Talent Hunt - Guide the Wire
                            </Typography>
                          
                        </Box>

                        <Box display="flex" gap={1} flexWrap="wrap" mb={2}>
                            {['Guidewire', 'Gosu', 'Gosu Scripting', 'Policy'].map((label) => (
                                <Chip key={label} label={label} size="small"  sx={{ fontSize: '0.75rem' }} />
                            ))}
                        </Box>

                        {/* Date and Enrollment */}
                        <Box display="flex" alignItems="center" gap={1} mb={2}>
                            <EventIcon fontSize="small" color="action" />
                            <Typography variant="body2">30 Oct, 10:00 AM</Typography>
                            <Typography variant="body2" color="textSecondary">
                                • 447 Enrolled
                            </Typography>
                        </Box>
                        <Divider sx={{ borderStyle: 'dashed',mb:2 }} />

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Button variant="outlined" startIcon={<WorkOutlineIcon />}  sx={{ textTransform: 'none', fontWeight: 'bold' ,color: '#423BFA',borderRadius:'2rem'}}>
                                Job Offer
                            </Button>
                            <Button variant="text" sx={{ textTransform: 'none', fontWeight: 'bold' }}>
                                View Details
                            </Button>
                        </Box>
                    </CardContent>
                </EventCard>

                {/* Right Navigation Arrow */}
                <IconButton aria-label="next" sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </SectionContainer>
    );
};

export default JobSeekerUpComingEvents;
