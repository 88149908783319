import React from 'react';
import { Box, Typography, Paper, useMediaQuery, useTheme, Icon, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BusinessIcon from '@mui/icons-material/Business';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ComputerIcon from '@mui/icons-material/Computer';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import EditIcon from '@mui/icons-material/Edit';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';


const roles = [
  { title: 'Business Development', openings: 1508 },
  { title: 'Retail / Counter Sales', openings: 1101 },
  { title: 'Marketing', openings: 873 },
  { title: 'Cook / Chef / Baker', openings: 716 },
  { title: 'Logistics/Warehouse', openings: 634 },
  { title: 'Back Office', openings: 556 },
  { title: 'Hardware & Network', openings: 85 },
  { title: 'Electrician / Wireman', openings: 72 },
  { title: 'Content Writing', openings: 70 },
  { title: 'Pharmacist', openings: 70 },
  { title: 'IT Support', openings: 69 },
  { title: 'Doctor / Dentist', openings: 60 },
];


// Mapping roles to icons
const roleIcons = {
  'Business Development': <BusinessIcon />,
  'Retail / Counter Sales': <LocalAtmIcon />,
  'Marketing': <MenuBookIcon />,
  'Cook / Chef / Baker': <FastfoodIcon />,
  'Logistics/Warehouse': <LocalShippingIcon />,
  'Back Office': <ComputerIcon />,
  'Hardware & Network': <SettingsIcon />,
  'Electrician / Wireman': <HomeRepairServiceIcon />,
  'Content Writing': <EditIcon />,
  'Pharmacist': <LocalPharmacyIcon />,
  'IT Support': <SupportAgentIcon />,
  'Doctor / Dentist': <MedicalServicesIcon />,
};


const RoleCard = ({ title, openings, icon }) => (
  <Paper
    elevation={0}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '12px',
      margin: '10px',
      border: '1px solid #e0e0e0',
      borderRadius: 4,
      cursor: 'pointer',
      transition: 'transform 0.2s',
      '&:hover': { transform: 'scale(1.05)' },
      flex: '0 0 auto',
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon sx={{ fontSize: 32, color: 'text.secondary', mr: 1 }}>
        {icon}
      </Icon>
      <Box>
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {openings.toLocaleString()} openings
        </Typography>
      </Box>
    </Box>
    <ArrowForwardIosIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
  </Paper>
);


const JobSeekerTrendingJobs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={{ height: '70vh', }}>


      <Box sx={{ padding: 3 }} >
        <Typography variant="h4" fontWeight="bold" textAlign="center" mb={15}>
          Trending Job Roles on Vacanzi
        </Typography>

        <Box sx={{ overflow: 'hidden', position: 'relative', mb: 3 }}>
          <Box
            component="div"
            sx={{
              display: 'flex',
              gap: 2,
              animation: `marquee ${isMobile ? 5 : 10}s linear infinite`, // Increased speed further
              '&:hover': { animationPlayState: 'paused' },
            }}
          >
            {roles.concat(roles).map((role, index) => (
              <RoleCard
                key={`first-${index}`}
                title={role.title}
                openings={role.openings}
                icon={roleIcons[role.title]} // Pass the correct icon
              />
            ))}
          </Box>


        </Box>

        <Box sx={{ overflow: 'hidden', position: 'relative', mb: 3 }}>
          <Box
            component="div"
            sx={{
              display: 'flex',
              gap: 2,
              animation: `marqueeFast ${isMobile ? 7 : 12}s linear infinite`, // Increased speed
              '&:hover': { animationPlayState: 'paused' },
            }}
          >
            {roles.concat(roles).map((role, index) => (
              <RoleCard
                key={`second-${index}`}
                title={role.title}
                openings={role.openings}
                icon={roleIcons[role.title]} // Pass the correct icon
              />
            ))}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button
              endIcon={<ArrowForwardIosIcon />}
              variant="outlined"
              sx={{
                color: '#0e0593', // Font color
                padding: '12px 24px', // Increase padding for a larger button
                fontWeight: 'bold',
                fontSize: '1.2rem', // Make font bigger
                borderRadius: 3,
                position: 'relative', // Ensure it stays within the container's layout
                zIndex: 1, // Make sure the button is above other content
                '&:hover': {
                  backgroundColor: '#0e0593', // Background color on hover
                  color: 'white', // Change font color on hover
                  borderColor: '#423BFA', // Ensure border color remains the same
                },
              }}
            >
              View All
            </Button>
          </Box>
        </Box>

        <style jsx>{`
        @keyframes marquee {
          from { transform: translateX(0); }
          to { transform: translateX(-50%); }
        }
        @keyframes marqueeFast {
          from { transform: translateX(0); }
          to { transform: translateX(-50%); }
        }
      `}</style>
      </Box>
    </div>
  );
};


export default JobSeekerTrendingJobs;
