import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  Pagination,
  Typography,
  Box,
  IconButton,
  Button,
  Card,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/system";
import EmployerDrawer from "../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer";
import RecruiterModal from "./RecruiterModal";
import baseURL from "../../../api/baseUrl";

const CustomPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#f9f9f9",
  borderRadius: "8px",
  boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
}));

const EmployerTeamMembers = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [expandedPermissions, setExpandedPermissions] = useState({});
  const rowsPerPage = 4;

  useEffect(() => {
    const fetchUsers = async () => {
      const organizationId = localStorage.getItem("organizationId");
      const authToken = localStorage.getItem("token"); // Retrieve token from local storage
      // Check if authToken is available
      if (!authToken) {
        console.error("No auth token found");
        return;
      }
  
      try {
        const response = await fetch(
          `${baseURL}/organization/${organizationId}/getallusers`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`, // Add the authToken here
            },
          }
        );
  
        // Check for network errors
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
  
        const data = await response.json();
  
        // Validate response data
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          console.error("Expected an array of users, but received:", data);
        }
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
  
    fetchUsers();
  }, []);

  const handleClickOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handleRoleChange = (event) => {
    setRoleFilter(event.target.value);
    setPage(1);
  };

  const handleTogglePermissions = (userId) => {
    setExpandedPermissions((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  const filteredUsers = users.filter(
    (user) =>
      (user.fullName?.toLowerCase().includes(search.toLowerCase()) ||
        user.email?.toLowerCase().includes(search.toLowerCase())) &&
      (roleFilter ? user.role === roleFilter : true)
  );

  return (
    <EmployerDrawer>
      <Paper
        sx={{
          marginBottom: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          boxShadow: 3,
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#333",
            marginRight: 2,
          }}
        >
          Team Members
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Card
            sx={{
              padding: 1.5,
              backgroundColor: "#e0e0e0",
              boxShadow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", color: "#555" }}
            >
              No. of Recruiters
            </Typography>
          </Card>

          <Button
            variant="contained"
            color="primary"
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 2,
              padding: 1.5,
            }}
            onClick={() => handleClickOpen(null)}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", color: "#fff" }}
            >
              Add Recruiter
            </Typography>
          </Button>
        </Box>
      </Paper>

      <RecruiterModal open={open} handleClose={handleClose} user={selectedUser} />

      <CustomPaper>
        <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
          <TextField
            variant="outlined"
            placeholder="Search by name or email"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            sx={{ marginRight: 2 }}
          />
          <Select
            labelId="role-select-label"
            id="role-select"
            value={roleFilter}
            displayEmpty
            onChange={handleRoleChange}
            sx={{ minWidth: 150 }}
          >
            <MenuItem value="">
              <em>Role</em>
            </MenuItem>
            <MenuItem value={"recruiter_admin"}>Recruiter Admin</MenuItem>
            <MenuItem value={"recruiter"}>Recruiter</MenuItem>
            <MenuItem value={"manager"}>Manager</MenuItem>
          </Select>
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name & Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Permissions</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((user) => {
                  const permissions = Object.entries(user.permissions);
                  const isExpanded = expandedPermissions[user._id];

                  return (
                    <TableRow
                      hover
                      key={user._id}
                      sx={{ transition: "height 0.3s" }}
                    >
                      <TableCell>
                        <Typography variant="body1" fontWeight="bold">
                          {user.fullName || "N/A"}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {user.email || "N/A"}
                        </Typography>
                      </TableCell>
                      <TableCell>{user.role || "N/A"}</TableCell>
                      <TableCell>
                        {permissions.length > 0 && (
                          <>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography variant="body2">
                                {permissions.slice(0, 1).map(([key, value]) => (
                                  <Box
                                    key={key}
                                    sx={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: "bold",
                                        display: "inline",
                                        color: value ? "green" : "red",
                                      }}
                                    >
                                      {key
                                        .replace(/([A-Z])/g, " $1")
                                        .toUpperCase()}
                                      :
                                    </Typography>{" "}
                                    {value ? "Yes" : "No"}
                                  </Box>
                                ))}
                              </Typography>
                              {permissions.length > 1 && (
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    handleTogglePermissions(user._id)
                                  }
                                >
                                  {isExpanded
                                    ? "Show less"
                                    : `+${permissions.length - 1}`}
                                </Button>
                              )}
                            </Box>
                            {isExpanded &&
                              permissions.slice(1).map(([key, value]) => (
                                <Box key={key} sx={{ mb: 1 }}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontWeight: "bold",
                                      display: "inline",
                                      color: value ? "green" : "red",
                                    }}
                                  >
                                    {key
                                      .replace(/([A-Z])/g, " $1")
                                      .toUpperCase()}
                                    :
                                  </Typography>{" "}
                                  {value ? "Yes" : "No"}
                                </Box>
                              ))}
                          </>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleClickOpen(user)}
                          sx={{ color: "#555" }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 2,
          }}
        >
          <Typography variant="body2">
            {`Showing ${Math.min(
              rowsPerPage * page,
              filteredUsers.length
            )} of ${filteredUsers.length} records`}
          </Typography>
          <Pagination
            count={Math.ceil(filteredUsers.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </CustomPaper>
    </EmployerDrawer>
  );
};

export default EmployerTeamMembers;
