import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import getTheApp from '../../../assets/images/getTheApp.png';
import { styled } from '@mui/system';
import googlePlay from '../../../assets/images/googlePlay.png';
import appStore from '../../../assets/images/appStore.png';
import qrCode from '../../../assets/images/qrCode.png';

const SectionContainer = styled(Container)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '32px',
    marginBottom: '40px',
    borderRadius: '30px',
    backgroundColor: '#fff8f0',
    backgroundImage: 'linear-gradient(to top, rgba(0, 123, 255, 0.3), rgba(0, 123, 255, 0.1) 50%, #fff8f0 90%)',
    '@media (max-width: 900px)': {
        flexDirection: 'column',
        padding: '16px',
    },
});
    
const LeftSection = styled(Box)({
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px', // Adds padding for better centering
    '@media (max-width: 900px)': {
        width: '100%',
    },
});

const QRCodeContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 0',
    width: '100%', // Ensures QR code takes up full width of LeftSection
});

const AppLinksContainer = styled(Box)({
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    width: '100%', // Centers links within LeftSection
});

const JobSeekerGetTheApp = () => {
    return (
        <SectionContainer>
            <LeftSection>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'bold', color: '#000' }}
                    gutterBottom
                >
                    Stand out among recruiters with a video profile
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#555' }}>
                    Available for both Android and iOS apps
                </Typography>

                {/* QR Code Container */}
                <QRCodeContainer>
                    <img src={qrCode} alt="QR Code" />
                </QRCodeContainer>

                {/* App Links */}
                <AppLinksContainer>
                    <img src={appStore} alt="App Store" style={{ width: '120px' }} />
                    <img src={googlePlay} alt="Google Play" style={{ width: '120px' }} />
                </AppLinksContainer>
            </LeftSection>

            {/* Right Section: Image */}
            <Box
                sx={{
                    width: { xs: '100%', md: '40%' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: { xs: 4, md: 0 },
                    position: 'relative',
                }}
            >
                <Box
                    component="img"
                    src={getTheApp || 'https://via.placeholder.com/300'}
                    alt="Promotional"
                    sx={{
                        width: '100%',
                        objectFit: 'contain',
                        borderRadius: 2,
                    }}
                />
            </Box>
        </SectionContainer>
    );
};

export default JobSeekerGetTheApp;
