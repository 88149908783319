import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid, useTheme, useMediaQuery, Box, Typography } from '@mui/material';
import Filters from '../../../components/JobSeekerComponents/JobSeekerFilters/Filters/FiltersJobSeeker/Filters';
import JobListComponent from '../../../components/JobSeekerComponents/JobSeekerFilters/JobListComponent/JobListComponent';
import JobDetailComponent from '../../../components/JobSeekerComponents/JobSeekerFilters/JobDetailsComponent/JobDetailComponent';
import signupVideo from '../../../assets/videos/videoplayback2.mp4';
import NavBar from '../../../components/JobSeekerComponents/NavBar/NavBar';
import Footer from '../../../components/JobSeekerComponents/Footer/Footer';
import JobCategories from '../../../components/JobSeekerComponents/JobCategories/JobCategories';
import baseURL from '../../../api/baseUrl';
import { differenceInHours, differenceInDays } from 'date-fns';

const convertExperienceToYears = (experienceString) => {
  const match = experienceString.match(/^(\d+)/);
  return match ? parseInt(match[1], 10) : 0;
};

const JobSeekerFilter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [datePosted, setDatePosted] = useState('');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [sortBy, setSortBy] = useState('relevant');
  const [experience, setExperience] = useState([0, 30]);
  const [sortedJobs, setSortedJobs] = useState([]);
  const [salary, setSalary] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [highestEducation, setHighestEducation] = useState('graduate');

  const location = useLocation();
  const { keywords, jobCity } = location.state || {};

  useEffect(() => {
    const fetchJobs = async () => {
      const authToken = localStorage.getItem('authToken'); // Replace 'yourTokenKey' with the key used to store the token in localStorage      
      let queryParams = '';
      if (keywords) queryParams += `keywords=${encodeURIComponent(keywords)}&`;
      if (jobCity) queryParams += `jobCity=${encodeURIComponent(jobCity)}`;

      const url = queryParams ? `${baseURL}/jobs/all?${queryParams}` : `${baseURL}/jobs/all`;

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setJobs(data?.jobs || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [keywords, jobCity]);

  useEffect(() => {
    const filterJobs = () => {
      return jobs.filter((job) => {
        const jobExperience = convertExperienceToYears(job.totalExpRequired || '0 years');
        const isExperienceValid = jobExperience >= experience[0] && jobExperience <= experience[1];
        const isSalaryValid = salary.length === 0 || salary.some(range =>
          (job.minFixedSalary >= range.min && job.minFixedSalary <= range.max) ||
          (job.maxFixedSalary >= range.min && job.maxFixedSalary <= range.max)
        );

        const isLocationValid = selectedLocations.length === 0 || selectedLocations.includes(job.jobCity);

        const now = new Date();
        const jobPostedDate = new Date(job.postedDate);

        let isDateValid = true;
        if (datePosted) {
          if (datePosted === 'last24Hours') {
            isDateValid = differenceInHours(now, jobPostedDate) <= 24;
          } else if (datePosted === 'last3Days') {
            isDateValid = differenceInDays(now, jobPostedDate) <= 3;
          } else if (datePosted === 'last7Days') {
            isDateValid = differenceInDays(now, jobPostedDate) <= 7;
          }
        }

        // Add filtering based on highest education
        const educationLevels = ['diploma', 'graduate', 'postGraduate'];
        const educationIndex = educationLevels.indexOf(highestEducation);
        const jobEducationIndex = educationLevels.indexOf(job.minEducation);


        console.log('job.minEducation',job.minEducation);
        

        // Filter jobs based on education level
        const isEducationValid = educationIndex === -1 || (jobEducationIndex !== -1 && jobEducationIndex >= educationIndex);

        return isExperienceValid && isSalaryValid && isLocationValid && isDateValid && isEducationValid;
      });
    };

    const sortJobs = (jobsToSort, criteria) => {
      return [...jobsToSort].sort((a, b) => {
        switch (criteria) {
          case 'salaryHighToLow':
            return b.minFixedSalary - a.minFixedSalary;
          case 'datePostedNewToOld':
            return new Date(b.postedDate) - new Date(a.postedDate);
          default:
            return 0;
        }
      });
    };

    const filteredJobs = filterJobs();
    const sorted = sortJobs(filteredJobs, sortBy);
    console.log('Sorted Jobs:', sorted);

    setSortedJobs(sorted);
  }, [jobs, experience, salary, selectedLocations, datePosted, sortBy, highestEducation]);

  const handleSelectJob = (id) => {
    setSelectedJobId(id);
  };

  const handleBack = () => {
    setSelectedJobId(null);
  };

  const selectedJob = sortedJobs.find((job) => job._id === selectedJobId);

  const updateJobStatus = (jobId, isSaved) => {
    setJobs((prevJobs) =>
      prevJobs.map((job) =>
        job._id === jobId ? { ...job, isSaved } : job
      )
    );
  };

  return (
    <div>
      <NavBar />
      <Container sx={{ padding: isMobile ? '2rem 1rem' : '4rem' }}>
        <JobCategories />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Filters
              onSortByChange={setSortBy}
              onExperienceChange={setExperience}
              experience={experience}
              onSalaryChange={setSalary}
              salary={salary}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              datePosted={datePosted}
              onDatePostedChange={setDatePosted}
              highestEducation={highestEducation}
              onHighestEducationChange={setHighestEducation}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            {loading && <Typography variant="h6">Loading...</Typography>}
            {error && <Typography variant="h6">Error: {error}</Typography>}
            {!loading && !error && (
              selectedJobId ? (
                <JobDetailComponent job={selectedJob} onBack={handleBack} />
              ) : (
                <JobListComponent jobs={sortedJobs} onSelectJob={handleSelectJob} onJobUpdate={updateJobStatus} />
              )
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              sx={{
                width: '100%',
                height: isMobile ? '50vh' : '100vh',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                marginTop: isMobile ? '1rem' : 0,
              }}
            >
              <video autoPlay loop muted
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '0.5rem'
                }}
              >
                <source src={signupVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default JobSeekerFilter;
