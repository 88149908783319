import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button, Typography, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {jwtDecode} from 'jwt-decode';
import EmployerDrawer from '../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer';
import JobCard from '../../../components/EmployerComponents/EmployerJobListingComponents/JobCard';
import JobFilterButtons from '../../../components/EmployerComponents/EmployerJobListingComponents/EmployerJobFilterTabs';
import { createJobPost } from '../../../api/jobPostAPI';
import baseURL from '../../../api/baseUrl';

const EmployerJobListings = () => {
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState(0); // Managing active state for the filter buttons
  const [userData, setUserData] = useState(null); // State to store user data
  const [jobs, setJobs] = useState([]); // State to store job postings

  useEffect(() => {
    // Retrieve user data from localStorage
    const organizationId = localStorage.getItem('organizationId');
    const role = localStorage.getItem('role');
    const token = localStorage.getItem('token');
    const EmployerOranizationId = localStorage.getItem('EmployerOrganizationId');


    if (token) {
      try {
        // Decode the token
        const decodedToken = jwtDecode(token);

        // Set user data from the decoded token
        setUserData({
          organizationId: decodedToken.organizationId || organizationId,
          role: decodedToken.role || role,
          token
        });

        // Fetch job postings
        fetchJobPostings(decodedToken.organizationId || organizationId || EmployerOranizationId, token);

      } catch (error) {
        console.error('Error decoding token:', error);
      }
    } else {
      // Redirect to login if no token found
      navigate('/employerSignIn');
    }
  }, [navigate]);

  const fetchJobPostings = async (organizationId, token,EmployerOranizationId) => {
    try {
      const response = await fetch(`${baseURL}/jobpost/getalljobs/${organizationId || EmployerOranizationId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          'Content-Type': 'application/json'
        },
      });
      const data = await response.json();

      if (response.ok) {
        setJobs(data.jobPostings);
      } else {
        console.error('Error fetching job postings:', data.message);
      }
    } catch (error) {
      console.error('Error fetching job postings:', error);
    }
  };

  const handleFilterButtonClick = (index) => {
    setActiveFilter(index);
  };

  const handlePostJobClick = async () => {
    try {
      const data = await createJobPost(userData.organizationId, userData.token); // Use userData here
      navigate('/employersRegistrationProfile', { state: { jobData: data } });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Filter jobs based on the selected filter
  const filteredJobs = jobs.filter((job) => {
    if (activeFilter === 0) {
      return true; // All jobs
    } else if (activeFilter === 1) {
      return job.status === 'active'; // Active jobs
    } else if (activeFilter === 2) {
      return job.status === 'expired'; // Expired jobs
    } else if (activeFilter === 3) {
      return job.planStatus === 'unpaid'; // Jobs with unpaid plans
    }
    return true;
  });

  
  return (
    <EmployerDrawer>
      <Container>
        <Box sx={{ padding: 2 }}>
          {/* Title and Post a New Job Button */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
              All Jobs
            </Typography>
            <Button
              onClick={handlePostJobClick}
              sx={{
                backgroundColor: '#423bfa',
                color: '#fff',
                fontWeight: 'bold',
                textTransform: 'none',
                borderRadius: 10,
                border: '1px solid black',
                display: 'flex',
                alignItems: 'center',
                marginTop: { xs: 2, sm: 0 },
                '&:hover': {
                  backgroundColor: '#fff',
                  color: '#423bfa',
                  border: '1px solid #423bfa',
                },
              }}
            >
              <AddIcon sx={{ marginRight: 1 }} />
              Post a new job
            </Button>
          </Box>

          {/* Job Filter Buttons */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center', marginBottom: 5 }}>
            <JobFilterButtons activeFilter={activeFilter} onFilterClick={handleFilterButtonClick} />
          </Box>

          {/* Job Cards */}
          <Grid container spacing={2}>
            {filteredJobs.slice().reverse().map((job) => (
              <Grid item xs={12} key={job._id}>
                <JobCard
                  job={{
                    title: job.jobTitle,
                    status: job.status,
                    location: job.jobCity,
                    postedOn: new Date(job.postedDate).toLocaleDateString(),
                    postedBy: job.recruiter_Name,
                    company: job.hiringFor,
                    actionPending: job.actionPending,
                    allCandidates: job.allCandidates,
                    planRequired: job.planStatus,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </EmployerDrawer>
  );
};

export default EmployerJobListings;
