import React from 'react';
import { Box, TextField, Button, InputAdornment, useTheme, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const SearchBar = ({ title, location, onTitleChange, onLocationChange, onSearch,}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? 'column' : 'row'}
      alignItems="center"
      backgroundColor= '#ffffff'
      borderRadius="50px"
      padding={isSmallScreen ? '10px' : '5px 20px'}
      gap={isSmallScreen ? 2 : 1}
      boxShadow="0 2px 4px rgba(0,0,0,0.1)"
    >
      {/* Skill Search Input */}
      <TextField
        variant="standard"
        placeholder="Search jobs by 'skill'"
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: '#a3a3a3' }} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        sx={{
          flex: 1,
          backgroundColor: '#ffffff',
          borderRadius: '50px',
          padding: '8px 20px',
          '& .MuiInputBase-input': { padding: 0 },
        }}
      />

      {/* Location Search Input */}
      <TextField
        variant="standard"
        placeholder="Search for an area or..."
        value={location}
        onChange={(e) => onLocationChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnOutlinedIcon style={{ color: '#a3a3a3' }} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        sx={{
          flex: 1,
          backgroundColor: '#ffffff',
          borderRadius: '50px',
          padding: '8px 20px',
          '& .MuiInputBase-input': { padding: 0 },
        }}
      />

      {/* Search Button */}
      <Button
        variant="contained"
        onClick={onSearch}
        sx={{
          backgroundColor: '#2c9a8e',
          color: '#ffffff',
          borderRadius: '50px',
          textTransform: 'none',
          padding: '8px 20px',
          marginLeft: isSmallScreen ? 0 : '8px',
          '&:hover': { backgroundColor: '#21867a' },
        }}
      >
        Search jobs
      </Button>
    </Box>
  );
};

export default SearchBar;
