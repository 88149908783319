import React, { useState } from 'react';
import { Container, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LandingImage from '../../../assets/images/VacanziMobileLanding.png';
import SearchBar from '../LandingSearchBar/SearchBar';

const JobSeekerJobSearch = () => {
  const [titleSearch, setTitleSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate('/findJobs', {
      state: {
        keywords: titleSearch,
        jobCity: locationSearch,
      },
    });
  };

  return (
    <Box sx={{ backgroundColor: '#EDEDFC', padding: '2rem', minHeight: '100vh', position: 'relative' }}>
      <Container maxWidth="xl">
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          sx={{ position: 'relative', zIndex: 2, mt: { xs: 0, md: 8 } }} // Adjusted margin top for spacing
        >
          {/* Left Section - Typography and SearchBar Combined */}
          <Box
            sx={{
              width: { xs: '100%', md: '60%' },
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', md: 'center' },
              textAlign: { xs: 'center', md: 'left' },
              mb: { xs: 4, md: 0 },
              justifyContent: 'center',
              gap: 2,
              pt: { xs: 2, md: 4 }, // Added padding top for extra spacing
            }}
          >
            <Typography
              variant={isSmallScreen ? 'h6' : 'h2'}
              component="h1"
              gutterBottom
              sx={{ color: '#000', fontWeight: 'bold' }}
            >
              Find the perfect job for you
            </Typography>

            <SearchBar
              title={titleSearch}
              location={locationSearch}
              onTitleChange={setTitleSearch}
              onLocationChange={setLocationSearch}
              onSearch={handleSearch}
              sx={{
                width: { xs: '100%', sm: '80%', md: '70%' },
                margin: '0 auto', // Center the search bar on mobile
              }}
            />
          </Box>
        </Box>


        {/* Right Section - Image positioned at the bottom right */}
        <Box
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            bottom: { md: 0 },
            right: { md: 250 },
            width: { xs: '100%', sm: '80%', md: '20%' },
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            mt: { xs: 4, md: 0 },
            zIndex: 1,
          }}
        >
          <img
            src={LandingImage}
            alt="Job search"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default JobSeekerJobSearch;
