import React from 'react';
import { Box, Container, Typography, Paper, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slider from 'react-slick';
import { styled } from '@mui/system';
import interviewprep from '../../../assets/images/prepareInterview.png';

const roles = [
    { title: 'Product Manager', openings: '939' },
    { title: 'UI / UX Designer', openings: '1.4K+' },
    { title: 'Research Analyst', openings: '142' },
    { title: 'Branch Manager', openings: '290' },
    { title: 'Functional Consultant', openings: '4K+' },
    { title: 'Chartered Accountant', openings: '767' },
];
const SectionContainer = styled(Container)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '32px',
    marginBottom: '40px',
    borderRadius: '30px',
    backgroundColor: '#fff8f0',
    backgroundImage: 'linear-gradient(to top, rgba(0, 123, 255, 0.3), rgba(0, 123, 255, 0.1) 50%, #fff8f0 90%)',
    '@media (max-width: 900px)': {
        flexDirection: 'column',
        padding: '16px',
    },
});
const LeftSection = styled(Box)({
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    '@media (max-width: 900px)': {
        width: '100%',
    },
});


const RightSection = styled(Box)({
    width: '55%',
    position: 'relative',
    backgroundColor: '#ffffff',
    padding: '2rem',
    borderRadius: '2em',
    '@media (max-width: 900px)': {
        width: '100%',
        marginTop: '24px',
    },
});

const RoleCard = styled(Paper)({
    width: '10px',
    height: '80px',
    padding: '16px',
    margin: '8px', // Add margin for spacing between cards
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    borderRadius: '12px',
    cursor: 'pointer',
    border: '1px solid #e0e0e0',
    transition: 'transform 0.2s, border-color 0.2s',
    '&:hover': { transform: 'scale(1.03)', borderColor: '#1976d2' },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});


const ArrowButton = ({ direction, onClick }) => (
    <IconButton
        onClick={onClick}
        sx={{
            position: 'absolute',
            top: '50%',
            [direction === 'left' ? 'left' : 'right']: '-16px',
            transform: 'translateY(-50%)',
            backgroundColor: '#ffffff',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
            '&:hover': {
                backgroundColor: '#f0f0f0',
            },
        }}
    >
        {direction === 'left' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
    </IconButton>
);

const JobSeekerInterViewPrep = () => {
    const sliderSettings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    speed: 500,
    centerMode: true,       // Enable center mode
    centerPadding: '50px',  // Adjust the gap between cards
    responsive: [
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};


    const sliderRef = React.useRef(null);

    

    return (
        <SectionContainer>
            <LeftSection>
                <img src={interviewprep} alt="Discover jobs illustration" style={{ width: '100%', borderRadius: '8px' }} />  
            </LeftSection>

            <RightSection>
            <Typography variant="h6" fontWeight='bold' gutterBottom>Interview questions by company</Typography>

                <Slider ref={sliderRef} {...sliderSettings}>
                    {roles.map((role, index) => (
                        <RoleCard key={index} elevation={0}>
                            <Typography fontSize='14px' fontWeight="bold">
                                {role.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {role.openings} Jobs
                            </Typography>
                        </RoleCard>
                    ))}
                </Slider>
                <ArrowButton direction="left" onClick={() => sliderRef.current.slickPrev()} />
                <ArrowButton direction="right" onClick={() => sliderRef.current.slickNext()} />
                <Slider ref={sliderRef} {...sliderSettings}>
                    {roles.map((role, index) => (
                        
                        <RoleCard key={index} elevation={0}>
                            <Typography fontSize='14px' fontWeight="bold">
                                {role.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {role.openings} Jobs
                            </Typography>
                        </RoleCard>
                    ))}
                </Slider>
                <ArrowButton direction="left" onClick={() => sliderRef.current.slickPrev()} />
                <ArrowButton direction="right" onClick={() => sliderRef.current.slickNext()} />
                <Slider ref={sliderRef} {...sliderSettings}>
                    {roles.map((role, index) => (
                        <RoleCard key={index} elevation={0}>
                            <Typography fontSize='14px' fontWeight="bold">
                                {role.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {role.openings} Jobs
                            </Typography>
                        </RoleCard>
                    ))}

                </Slider>
                <ArrowButton direction="left" onClick={() => sliderRef.current.slickPrev()} />
                <ArrowButton direction="right" onClick={() => sliderRef.current.slickNext()} />
            </RightSection>

        </SectionContainer>
    );
};

export default JobSeekerInterViewPrep;
