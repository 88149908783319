// src/pages/Home/Home.jsx
import React from 'react';
import ResponsiveAppBar from '../../components/JobSeekerComponents/NavBar/NavBar';
// import HowItWorks from '../../components/JobSeekerComponents/HowItWorks/HowItWorks';
// import SearchCategories from '../../components//JobSeekerComponents/Catogories/SearchCategories';
// import LatestJobOpenings from '../../components/JobSeekerComponents/LatestJobs/LatestJobs';
import FeaturedJobs from '../../components/JobSeekerComponents/FeaturedJobs/FeaturedJobs';
import FeaturedEmployers from '../../components/JobSeekerComponents/FeaturedEmployers/FeaturedEmployers';
import Footer from '../../components/JobSeekerComponents/Footer/Footer';
import JobSeekerJobSearch from '../../components/JobSeekerComponents/JobSeekerSearch/jobSeekerJobSearch';
import JobSeekerTrendingJobs from '../../components/JobSeekerComponents/JobSeekerTrendingJobs/JobSeekerTrendingJobs';
import JobSeekerGetTheApp from '../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerGetTheApp';
import DiscoverJobs from '../../components/JobSeekerComponents/JobSeekerHomePageBanners/DiscoverJobsBanner';
import JobSeekerUpComingEvents from '../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerUpComingEvents';
import JobSeekerInterViewPrep from '../../components/JobSeekerComponents/JobSeekerHomePageBanners/JobSeekerIntreviewPrep';

const Home = () => {

  return (
    <>
      <ResponsiveAppBar />
      <JobSeekerJobSearch/>
      {/* <HowItWorks /> */}
      {/* <SearchCategories /> */}
      <JobSeekerTrendingJobs/>
      <DiscoverJobs/>
      {/* <LatestJobOpenings /> */}
      <FeaturedJobs/>
      <FeaturedEmployers/>
      <JobSeekerInterViewPrep/>
      <JobSeekerUpComingEvents/>
      <JobSeekerGetTheApp/>
      <Footer/>
    </>
  );
};

export default Home;
